import { gql } from '@apollo/client';

export const GET_DASHBOARD_USER_STAT = gql`
query DashboardUserStatistic{
    dashboardUserStatistic{
        commAmt
        activePlayer
    }
}
`;

export const GET_DASHBOARD_REGISTERED_USER_STAT = gql`
query DashboardRegisteredUsers{
    dashboardRegisteredUsers{
        dashboardStat {
            period
            count
            amount
        }
    }
}
`;

export const GET_DASHBOARD_DEPOSIT_STAT = gql`
query DashboardDeposit{
    dashboardDeposit{
        dashboardStat {
            period
            count
            amount
        }
    }
}
`;

export const GET_DASHBOARD_FIRST_DEPOSIT_STAT = gql`
query DashboardFirstDeposit{
    dashboardFirstDeposit{
        dashboardStat {
            period
            count
            amount
        }
    }
}
`;

export const GET_DASHBOARD_WITHDRAWAL_STAT = gql`
query DashboardWithdrawal{
    dashboardWithdrawal{
        dashboardStat {
            period
            count
            amount
        }
    }
}
`;

export const GET_DASHBOARD_BONUS_STAT = gql`
query DashboardBonus{
    dashboardBonus{
        dashboardStat {
            period
            count
            amount
        }
    }
}
`;

export const GET_DASHBOARD_TURNOVER_STAT = gql`
query DashboardTurnover{
    dashboardTurnover{
        dashboardStat {
            period
            count
            amount
        }
    }
}
`;

export const GET_DASHBOARD_VIP_TRANS_STAT = gql`
query DashboardVIPTrans{
    dashboardVIPTrans{
        dashboardStat {
            period
            count
            amount
        }
    }
}
`;

export const GET_DASHBOARD_WINLOSS_STAT = gql`
query DashboardWinLoss{
    dashboardWinLoss{
        dashboardStat {
            period
            count
            amount
        }
    }
}
`;



