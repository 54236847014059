import React from "react";
import NoMatch from "./NoMatch";

export interface RouteInfo {
    path: string,
    component: any,
    routes?: RouteInfo[],
    lazy?: boolean,
    protected?: boolean
}

const ReactLazyPreload = (importStatement: any) => {
    const Component: any = React.lazy(importStatement);
    Component.preload = importStatement;
    return Component;
};

//preload pages
const Dashboard = ReactLazyPreload(() => import('./pages/dashboard/Dashboard'));
Dashboard.preload();

const Member = ReactLazyPreload(() => import('./pages/member-list/MemberList'));
Member.preload();

const CommReport = ReactLazyPreload(() => import('./pages/commission-report/CommissionReport'));
CommReport.preload();

const BankAccounts = ReactLazyPreload(() => import('./pages/bank-accounts/BankAccounts'));
BankAccounts.preload();

const RegFtd = ReactLazyPreload(() => import('./pages/reg-ftd/RegFtd'));
RegFtd.preload();

const Performance = ReactLazyPreload(() => import('./pages/performance/Performance'));
Performance.preload();

const Profile = ReactLazyPreload(() => import('./pages/profile/Profile'));
Profile.preload();

export const routes: RouteInfo[] = [
    {
        path: "/login",
        component: React.lazy(() => import('./pages/login/Login'))
    }, {
        path: "/dashboard",
        component: Dashboard,
        protected: true
    }, {
        path: "/report/members",
        component: Member,
        protected: true
    }, {
        path: "/report/commission",
        component: CommReport,
        protected: true
    }, {
        path: "/account/bank-accounts",
        component: BankAccounts,
        protected: true
    }, {
        path: "/report/registration-ftd",
        component: RegFtd,
        protected: true
    }, {
        path: "/report/performance",
        component: Performance,
        protected: true
    }, {
        path: "/account/profile",
        component: Profile,
        protected: true
    }, {
        path: "*",
        component: NoMatch
    }
];