import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu, Badge, Skeleton } from 'antd';
import './SideMenu.less';
import { useEffect, useState } from 'react';
import packageJson from '../../../../package.json';
import { useIntl } from 'react-intl';
const { Sider } = Layout;
const { SubMenu } = Menu;

interface Props {
    collapsed: boolean;
    setCollapse: any;
};

const SideMenu = ({ collapsed, setCollapse }: Props) => {
    const location = useLocation();
    const intl = useIntl();
    const [selectedKey, setSelectedKey] = useState<string>(location.pathname);
    const [subMenuKey, setSubMenuKey] = useState<string>();
    const [sideNavs, setSideNavs] = useState<any[]>();
    const icons = require(`@ant-design/icons`);

    useEffect(() => {
        const selectedKeys = location.pathname.split('/');
        setSelectedKey(location.pathname);
        const expandedSubMenu = selectedKeys.length > 2 ? `/${selectedKeys[1]}` : undefined;
        setSubMenuKey(expandedSubMenu);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        if (collapsed)
            setSubMenuKey(undefined);
        else {
            const selectedKeys = location.pathname.split('/');
            const expandedSubMenu = selectedKeys.length > 2 ? `/${selectedKeys[1]}` : undefined;
            setSubMenuKey(expandedSubMenu);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collapsed])

    const handleSubMenuClick = (e: any) => {
        if (!e || (e && e.length === 0)) {
            setSubMenuKey(undefined);
        } else if (e && e.length > 1) {
            setSubMenuKey(e[1]);
        } else if (e && e.length > 0) {
            setSubMenuKey(e[0]);
        }
    }

    const getIcon = (iconName: string): any => {
        const AntdIcon = icons[iconName.trim()];
        return AntdIcon ? <AntdIcon /> : null;
    }

    const renderMenu = () => {
        return (
            !sideNavs ?
                <Menu theme="dark" mode="inline" triggerSubMenuAction={collapsed ? "hover" : "click"} onOpenChange={handleSubMenuClick}
                    selectedKeys={[selectedKey ? selectedKey : '/']} openKeys={subMenuKey ? [subMenuKey] : undefined}>
                    <Menu.Item key={'/dashboard'} icon={getIcon('DashboardOutlined')} >
                        <Link to={'/dashboard'} onClick={handleMenuItemClick}>{intl.formatMessage({ id: 'DASHBOARD' })}</Link>
                    </Menu.Item>
                    <SubMenu key={'/account'} icon={getIcon('UserOutlined')}
                        title={<>{intl.formatMessage({ id: 'ACCOUNT' })}<Badge dot={false} style={{ marginLeft: 5 }} ></Badge></>} >
                        <Menu.Item key={'/account/profile'}>
                            <Link to={'/account/profile'}>{intl.formatMessage({ id: 'PROFILE' })} <Badge count={0} style={{ marginBottom: 5, marginLeft: 2 }} /></Link>
                        </Menu.Item>
                        <Menu.Item key={'/account/bank-accounts'}>
                            <Link to={'/account/bank-accounts'}>{intl.formatMessage({ id: 'BANK_ACCOUNT' })} <Badge count={0} style={{ marginBottom: 5, marginLeft: 2 }} /></Link>
                        </Menu.Item>
                    </SubMenu >
                    <SubMenu key={'/report'} icon={getIcon('FileTextOutlined')}
                        title={<>{intl.formatMessage({ id: 'REPORTS' })}<Badge dot={false} style={{ marginLeft: 5 }} ></Badge></>} >
                        <Menu.Item key={'/report/members'}>
                            <Link to={'/report/members'}>{intl.formatMessage({ id: 'REFERRED_MEMBER' })} <Badge count={0} style={{ marginBottom: 5, marginLeft: 2 }} /></Link>
                        </Menu.Item>
                        <Menu.Item key={'/report/registration-ftd'}>
                            <Link to={'/report/registration-ftd'}>{intl.formatMessage({ id: 'REGISTER_FTD' })} <Badge count={0} style={{ marginBottom: 5, marginLeft: 2 }} /></Link>
                        </Menu.Item>
                        <Menu.Item key={'/report/performance'}>
                            <Link to={'/report/performance'}>{intl.formatMessage({ id: 'PERFORMANCE' })} <Badge count={0} style={{ marginBottom: 5, marginLeft: 2 }} /></Link>
                        </Menu.Item>
                        <Menu.Item key={'/report/commission'}>
                            <Link to={'/report/commission'}>{intl.formatMessage({ id: 'COMMISSION' })} <Badge count={0} style={{ marginBottom: 5, marginLeft: 2 }} /></Link>
                        </Menu.Item>
                    </SubMenu >
                </Menu >
                :
                <div style={{ margin: '30px 15px' }}>
                    <Skeleton active />
                </div>
        );
    }

    const handleMenuItemClick = () => {
        if (window.outerWidth < 768) {
            setCollapse();
        }
    }

    return (
        <Sider className="slider-container" width={256} trigger={null} collapsible collapsed={collapsed} collapsedWidth={window.innerWidth < 768 ? 0 : 80}>
            {renderMenu()}
            <div className="version-text">
                v {packageJson.version}
            </div>
        </Sider>
    )
}

export default SideMenu;