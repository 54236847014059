import { useQuery } from '@apollo/client';
import './Dashboard.less';
import {
  GET_DASHBOARD_USER_STAT, GET_DASHBOARD_REGISTERED_USER_STAT, GET_DASHBOARD_DEPOSIT_STAT, GET_DASHBOARD_WITHDRAWAL_STAT, GET_DASHBOARD_BONUS_STAT,
  GET_DASHBOARD_TURNOVER_STAT, GET_DASHBOARD_VIP_TRANS_STAT, GET_DASHBOARD_WINLOSS_STAT, GET_DASHBOARD_FIRST_DEPOSIT_STAT
} from './Dashboard.gql';
import { useEffect, useState } from 'react';
import { Space, Spin } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useAuth } from '../../hooks/auth/AuthContext';
import DashboardStatContainer from './DashboardStatContainer';
import TableNumberFormatter from '../../shared/components/table-number-formatter/TableNumberFormatter';

const Dashboard = () => {
  const intl = useIntl();
  const { authState } = useAuth();
  const { data: userStatData, loading: userStatLoading } = useQuery(GET_DASHBOARD_USER_STAT,
    { fetchPolicy: "no-cache" });
  const { data: registeredStatData, loading: registeredStatLoading } = useQuery(GET_DASHBOARD_REGISTERED_USER_STAT,
    { fetchPolicy: "no-cache" });
  const { data: depositStatData, loading: depositStatLoading } = useQuery(GET_DASHBOARD_DEPOSIT_STAT,
    { fetchPolicy: "no-cache" });
  const { data: firstDepositStatData, loading: firstDepositStatLoading } = useQuery(GET_DASHBOARD_FIRST_DEPOSIT_STAT,
    { fetchPolicy: "no-cache" });
  const { data: withdrawalStatData, loading: withdrawalStatLoading } = useQuery(GET_DASHBOARD_WITHDRAWAL_STAT,
    { fetchPolicy: "no-cache" });
  const { data: bonusStatData, loading: bonusStatLoading } = useQuery(GET_DASHBOARD_BONUS_STAT,
    { fetchPolicy: "no-cache" });
  const { data: turnoverStatData, loading: turnoverStatLoading } = useQuery(GET_DASHBOARD_TURNOVER_STAT,
    { fetchPolicy: "no-cache" });
  const { data: vipTransStatData, loading: vipTransLoading } = useQuery(GET_DASHBOARD_VIP_TRANS_STAT,
    { fetchPolicy: "no-cache" });
  const { data: winLossData, loading: winLossLoading } = useQuery(GET_DASHBOARD_WINLOSS_STAT,
    { fetchPolicy: "no-cache" });
  const [userStat, setUserStat] = useState<any>(); 
  const [registeredStat, setRegisteredStat] = useState<any>();
  const [depositStat, setDepositStat] = useState<any>();
  const [firstDepositStat, setFirstDepositStat] = useState<any>();
  const [withdrawalStat, setWithdrawalStat] = useState<any>();
  const [bonusStat, setBonusStat] = useState<any>();
  const [turnoverStat, setTurnoverStat] = useState<any>();
  const [vipTransStat, setVipTransStat] = useState<any>();
  const [winLossStat, setWinLossStat] = useState<any>();

  useEffect(() => {
    if (userStatData && userStatData.dashboardUserStatistic) {
      setUserStat(userStatData.dashboardUserStatistic)
    }

  }, [userStatData])

  useEffect(() => {
    if (registeredStatData && registeredStatData.dashboardRegisteredUsers && registeredStatData.dashboardRegisteredUsers.dashboardStat) {
      setRegisteredStat(registeredStatData.dashboardRegisteredUsers.dashboardStat)
    }

  }, [registeredStatData])

  useEffect(() => {
    if (depositStatData && depositStatData.dashboardDeposit && depositStatData.dashboardDeposit.dashboardStat) {
      setDepositStat(depositStatData.dashboardDeposit.dashboardStat)
    }

  }, [depositStatData])

  useEffect(() => {
    if (firstDepositStatData && firstDepositStatData.dashboardFirstDeposit && firstDepositStatData.dashboardFirstDeposit.dashboardStat) {
      setFirstDepositStat(firstDepositStatData.dashboardFirstDeposit.dashboardStat)
    }

  }, [firstDepositStatData])

  useEffect(() => {
    if (withdrawalStatData && withdrawalStatData.dashboardWithdrawal && withdrawalStatData.dashboardWithdrawal.dashboardStat) {
      setWithdrawalStat(withdrawalStatData.dashboardWithdrawal.dashboardStat)
    }

  }, [withdrawalStatData])

  useEffect(() => {
    if (bonusStatData && bonusStatData.dashboardBonus && bonusStatData.dashboardBonus.dashboardStat) {
      setBonusStat(bonusStatData.dashboardBonus.dashboardStat)
    }

  }, [bonusStatData])

  useEffect(() => {
    if (turnoverStatData && turnoverStatData.dashboardTurnover && turnoverStatData.dashboardTurnover.dashboardStat) {
      setTurnoverStat(turnoverStatData.dashboardTurnover.dashboardStat)
    }

  }, [turnoverStatData])

  useEffect(() => {
    if (vipTransStatData && vipTransStatData.dashboardVIPTrans && vipTransStatData.dashboardVIPTrans.dashboardStat) {
      setVipTransStat(vipTransStatData.dashboardVIPTrans.dashboardStat)
    }

  }, [vipTransStatData])

  useEffect(() => {
    if (winLossData && winLossData.dashboardWinLoss && winLossData.dashboardWinLoss.dashboardStat) {
      setWinLossStat(winLossData.dashboardWinLoss.dashboardStat)
    }

  }, [winLossData])

  const columns: any = [
    {
      title: intl.formatMessage({ id: 'PERIOD' }),
      dataIndex: 'period',
      render: (text: any,) => {
        return (
          <Space size="middle">
            {intl.formatMessage({ id: text.toUpperCase() }) ?? '-'}
          </Space>
        )
      }
    }, {
      title: intl.formatMessage({ id: 'COUNT' }),
      dataIndex: 'count',
      render: (text: any,) => {
        return (
          <Space size="middle">
            {text ?? '-'}
          </Space>
        )
      }
    },]

  const amtColumn: any = [
    {
      title: intl.formatMessage({ id: 'AMOUNT' }),
      dataIndex: 'amount',
      render: (text: any,) => {
        return (
          <TableNumberFormatter number={text} decimalScale={2} />
        )
      }
    },]

  return (
    <>
      <div className="dashboard-container">
        <div className='dashboard-item-container'>
          <div className='dashboard-commission-container'>
            <div className='commission-title'><FormattedMessage id='COMMISSION' /></div>
            <div className='commission-container'>
              <div className='commission-period'><FormattedMessage id='DASHBOARD_PERIOD' /></div>
              <div className='commission-amt'>
                {userStatLoading ? <Spin /> :
                  <>
                    <FormattedMessage id='CURRENCY_SYMBOL_BDT' />
                    <NumberFormat
                      value={parseFloat(userStat?.commAmt).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      fixedDecimalScale
                      decimalScale={2}
                    />
                  </>

                }
              </div>
            </div>
          </div>
          <div className='dashboard-active-container'>
            <div className='commission-title'><FormattedMessage id='ACTIVE_PLAYERS' /></div>
            <div className='commission-container'>
              <div className='commission-period'><FormattedMessage id='DASHBOARD_PERIOD' /></div>
              <div className='commission-amt'>
                <NumberFormat
                  value={parseFloat(authState?.userProfile?.cmActiveAff ? authState?.userProfile?.cmActiveAff.toString() : '0').toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  fixedDecimalScale
                  decimalScale={0}
                />
              </div>
            </div>
          </div>
          <DashboardStatContainer name='REGISTERED_USERS' loading={registeredStatLoading} data={registeredStat} column={columns} />
          <DashboardStatContainer name='FIRST_DEPOSIT' loading={firstDepositStatLoading} data={firstDepositStat} column={[...columns, ...amtColumn]} />
          <DashboardStatContainer name='DEPOSIT' loading={depositStatLoading} data={depositStat} column={[...columns, ...amtColumn]} />
          <DashboardStatContainer name='WITHDRAWAL' loading={withdrawalStatLoading} data={withdrawalStat} column={[...columns, ...amtColumn]} />
          <DashboardStatContainer name='BONUS' loading={bonusStatLoading} data={bonusStat} column={[...columns, ...amtColumn]} />
          <DashboardStatContainer name='TURNOVER' loading={turnoverStatLoading} data={turnoverStat} column={[...columns, ...amtColumn]} />
          <DashboardStatContainer name='VIP_TRANS' loading={vipTransLoading} data={vipTransStat} column={[...columns, ...amtColumn]} />
          <DashboardStatContainer name='WIN_LOSS' loading={winLossLoading} data={winLossStat} column={[...columns, ...amtColumn]} />
        </div>
      </div>
    </>
  );
}

export default Dashboard;