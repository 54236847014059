import { gql } from "@apollo/client";

export const LOGIN = gql`
mutation Login($credential:InternalSignInInput){
    internalSignIn(signIn:$credential){
        a,
        r,
        sid,
        secret
    }
}`

export const SHAREHOLDER_SIGN_IN = gql`
    mutation SignIn($userName: String!, $password: String!){
        signIn(userName: $userName, password: $password){
            a,
            r,
            sid,
        }
    }
`;

export const SIGN_IN = gql`
    mutation SignIn($userName: String!, $password: String!, $companyId: String!){
        signIn(userName: $userName, password: $password, companyId:$companyId){
            a,
            r,
            sid,
        }
    }
`;

export const GET_USER_PROFILE = gql`
query UserProfile {
    userProfile {
		id,
		name,
		userName,
		phone,
		phoneVerified,
		email,
		emailVerified,
		gender,
		bankAccountStatus,
		loginPasswordStatus,
		isActive,
		requiredPasswordChange,
		address1,
		address2,
		city,
		state,
		postcode,
		dob,
		totalBalance,
		currency,
		referralCode,
		siteUrl,
		dobUpdated,
		ua,
		currentTime,
		lastUpdated,
		affMember,
		cmRegisteredAff,
		cmActiveAff,
		createdAt,
		lastLogin,
		lastWithdrawal
    }
}
`;