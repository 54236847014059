import { Layout } from 'antd';
import './GeneralFooter.less';
import { FormattedMessage } from 'react-intl';
const { Footer } = Layout;

const GeneralFooter = () => {
    return (
        <Footer className="site-layout-footer"><FormattedMessage id='PORTAL_NAME'/> ©2023</Footer>
    )
}

export default GeneralFooter;