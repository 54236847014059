import React, { useState } from 'react';
import { Dropdown, Layout, Menu } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  CopyOutlined
} from '@ant-design/icons';
import './GeneralHeader.less';
import { useAuth } from '../../../hooks/auth/AuthContext';
import { FormattedMessage, useIntl } from 'react-intl';
import copy from 'copy-to-clipboard';

const { Header } = Layout;

interface Props {
  collapsed: boolean;
  toggleSideNav?: () => void;
  logout?: () => void;
};

const GeneralHeader = ({ collapsed, toggleSideNav, logout }: Props) => {
  const { authState } = useAuth();
  const name = authState.userProfile && authState.userProfile?.name ?
    authState.userProfile?.name : '';
  const intl = useIntl();
  const refLink = `${authState?.userProfile?.siteUrl}/home/referral?refId=${authState?.userProfile?.referralCode ?? '-'}`;

  const onCopyClick = (text: string) => {
    copy(text);
    navigator.clipboard.writeText(refLink || '').then(() => {
      alert(intl.formatMessage({ id: 'SHAREHOLDER_REF_URL_COPIED' }))
    });
  }

  const menu = (
    <Menu>
      {/* <Menu.Item key="0" icon={<LockOutlined />}>
        <Link to="/settings/change-password"><FormattedMessage id='CHANGE_PASSWORD' /></Link>
      </Menu.Item>
      <SubMenu key={'lang'} icon={<CommentOutlined />}
        title={<FormattedMessage id='LANGUAGE' />} >
        <Menu.Item key={'lang_en'}>
          <div className='lang-item' onClick={() => { onLanguageClick('en') }}><FormattedMessage id='LANGUAGE_EN' /></div>
        </Menu.Item>
        <Menu.Item key={'lang_cn'}>
          <div className='lang-item' onClick={() => { onLanguageClick('zh-CN') }}><FormattedMessage id='LANGUAGE_CN' /></div>
        </Menu.Item>
        <Menu.Item key={'lang_ms'}>
          <div className='lang-item' onClick={() => { onLanguageClick('ms') }}><FormattedMessage id='LANGUAGE_BM' /></div>
        </Menu.Item>
      </SubMenu > */}
      <Menu.Item key="1" icon={<LogoutOutlined />} onClick={logout}><FormattedMessage id='LOGOUT' /></Menu.Item>
    </Menu>
  );

  const copyReferralUrl = () => {
    navigator.clipboard.writeText(authState?.userProfile?.siteUrl || '').then(() => {
      alert(intl.formatMessage({ id: 'SHAREHOLDER_REF_URL_COPIED' }))
    });
  }

  return (
    <>
      <Header className="site-layout-header" style={{ padding: 0 }}>
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'trigger',
          onClick: toggleSideNav,
        })}
        <div className='header-info-container'>
          <div className="home-promo-container">
            <div className="promo-title"><b><FormattedMessage id='DASHBOARD_HOME_AFF_PROMO_URL' /></b></div>
            <div className="promo-url">{refLink}</div>
            <div className="promo-copy-btn" onClick={() => { onCopyClick(refLink) }}><CopyOutlined /></div>
          </div>
          <Dropdown overlay={menu} className="profile-menu" trigger={['hover', 'click']}>
            <a className="ant-dropdown-link" href="/#">  <UserOutlined /><span className="profile-menu-title">{name}</span> </a>
          </Dropdown>
        </div>
      </Header>
    </>
  )
}

export default GeneralHeader;