import { Space } from 'antd';
import NumberFormat from 'react-number-format';

interface Props {
    number: any;
    decimalScale: any;
    suffix?: any;
}

const TableNumberFormatter = ({ number, decimalScale, suffix = '' }: Props) => {
    return (
        <Space size="middle">
            <div className={(+number < 0) ? 'negative-number' : 'normal-number'}>
                {(+number < 0) && '('}
                <NumberFormat
                    value={parseFloat(number ? (+number < 0) ? (+number * -1) : number : 0).toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                    fixedDecimalScale
                    decimalScale={decimalScale}
                    suffix={suffix}
                />
                {(+number < 0) && ')'}
            </div>
        </Space>
    )
}

export default TableNumberFormatter;