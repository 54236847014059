import './Dashboard.less';
import { Collapse, Skeleton, Table } from 'antd';
import { FormattedMessage } from 'react-intl';
const { Panel } = Collapse;

interface Props {
    loading: any;
    data: any;
    column: any;
    name: any
}

const DashboardStatContainer = ({ loading, data, column, name }: Props) => {
    return (
        <>
            <div className='stat-container'>
                <Collapse defaultActiveKey={name} expandIconPosition={'end'}>
                    <Panel key={name} header={name ? <FormattedMessage id={name} /> : ''} >
                        {loading ? <Skeleton /> :
                            <Table pagination={false} dataSource={data} columns={column} size='small' bordered />
                        }
                    </Panel>
                </Collapse>
            </div>
        </>
    );
}

export default DashboardStatContainer;